<template>
        <!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" id="collab_outline" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 856.6 464.5" style="enable-background:new 0 0 856.6 464.5;" xml:space="preserve">
        <rect class="fill__c" x="743.4" y="10.3" transform="matrix(0.9366 -0.3504 0.3504 0.9366 2.8805 280.4452)" width="66.1" height="243.9"/>
        <rect class="fill__c" x="-40.4" y="99.1" transform="matrix(0.3628 -0.9319 0.9319 0.3628 -71.205 160.2451)" width="243.9" height="66.1"/>
        <path d="M151.8,42.7c0,0,34.3,18.7,46.6,22.9c12,4,37.5,7.3,50,6.1c32.1-3.1,90.9-33.6,122.6-39.8s97.2-6.9,128.7,0
        c32.6,7.1,91.2,47.2,124.6,48c22.8,0.6,85-32.9,85-32.9"/>
        <path d="M784.5,248l-122.5,47.3c0,0-113.5-116-160.3-142c-21.3-11.8-72.2-21.4-96-16.3c-20.5,4.3-54.7,36.3-75.6,35.7
        c-13.6-0.4-40.8-22.2-40.9-35.7c-0.2-30.3,89.9-81.2,89.9-81.2l120.5-24"/>
        <path d="M200.5,313.2c3.1-19.9,36.9-60.6,54.1-71c7.9-4.7,26.9-6.6,34.7-1.8c8.6,5.3,16.3,24.4,15,34.4
        c-2.5,19.3-36.7,61.7-52,74.6c-7.9,6.7-27,5.1-37,2.5C203,349,199,323,200.5,313.2z"/>
        <path d="M263.7,347.8c2.8-18.3,33.6-55.9,49.3-65.5c7.2-4.4,24.5-6.1,31.7-1.7c7.8,4.9,14.8,22.5,13.7,31.8
        c-2.2,17.8-33.5,56.9-47.4,68.8c-7.2,6.2-24.6,4.7-33.8,2.3C266.1,380.8,262.4,356.8,263.7,347.8z"/>
        <path d="M318.1,386.8c2.6-17.2,31.3-52.5,46-61.4c6.7-4.1,22.9-5.7,29.5-1.6c7.3,4.6,13.8,21.1,12.8,29.8
        c-2.1,16.7-31.2,53.4-44.2,64.6c-6.7,5.8-22.9,4.4-31.5,2.2C320.2,417.8,316.8,395.3,318.1,386.8z"/>
        <path d="M372.9,420.5c2.5-16.3,29.7-49.7,43.5-58.1c6.3-3.9,21.6-5.4,27.9-1.5c6.9,4.3,13.1,20,12.1,28.2
        c-2,15.8-29.5,50.5-41.8,61.1c-6.3,5.5-21.7,4.1-29.8,2.1C375,449.8,371.7,428.5,372.9,420.5z"/>
        <line x1="75.1" y1="239.9" x2="200.2" y2="302"/>
        <path d="M662.1,289.7c0,15-1.7,44.1-13.3,53.6c-9.2,7.5-34.9,8.8-45.9,4.6c-16.6-6.4-48-46-48-46"/>
        <path d="M599.4,346.3c0,0,7.7,44.7-1.2,53c-10.1,9.5-36.8,1.5-48.5-5.9c-16.7-10.6-57.7-61.6-57.7-61.6"/>
        <path d="M547.3,388.3c0,0-5,31.7-12.9,37.3c-8.4,6-29.5,3.9-38.8-0.5c-11.6-5.6-39-29.1-39-29.1"/>
        <path d="M425.8,441.6c10.9,12.5,23.1,22.1,38.9,15.9c13-5.2,22.7-20.9,22.8-37.3"/>
    </svg>
</template>
<script>
    export default {
        props: {
            fill: {
                type: String
            }
        }
    }
</script>
