<template>
    <div class="header__c">
        <div class="mainText__c">
            {{ mainText }}
        </div>
        <div v-if="subText">
            {{ subText }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "HeaderSection",
        props: {
            mainText: String,
            subText: String,
        }
    }
</script>

<style scoped>
    @import "../scss/components/component-header.scss";
    /* .header__c {
        background-color: #00274c;
        color: #ffffff;
        margin-bottom: 30px;
        min-height: 450px;
        text-align: center;
    }

    .mainText__c,
    .subText__c {
        font-size: 3em;
    }

    .mainText__c {
        font-weight: 900;
        margin: 0;
        padding-top: 160px;
    } */
</style>