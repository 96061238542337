<template>
    <div class="filters">
        <!-- <h4 class="filter-title">{{ title }} <font-awesome-icon icon="caret-down" /></h4> -->
        <h4 @click="showFilters" class="filter-title">{{ title }} <font-awesome-icon icon="caret-down" /></h4>
        <div class="filters-container" :class="{ active: filtersActive }">
            <div class="filter"
                v-for="(filter, index) of filters"
                :key="index"
            >
                <input type="checkbox" :id="formatId(filter, index)" :name="filter" :value="filter" @change="boxChecked($event.target)">
                <label :for="formatId(filter, index)">{{ filter }}</label>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
      props: {
        id: Number,
        uid: Number,
        filters: Array,
        title: String,
      },
      data() {
        return {
            sCheckbox: [],
            filtersActive: false,
        }
      },
      computed: {
          formatedLabelName() {
              return (this.title.includes(" ")) ? this.title.replaceAll(" ", "_").toLowerCase() : this.title.toLowerCase()
          }
      },
      methods: {
        boxChecked(target) {
            switch (target.checked) {
                case true:
                    this.sCheckbox.push(target.value)
                    break
                default:
                    this.sCheckbox = this.sCheckbox.filter(f => f !== target.value)
                    break
            }

            this.$emit("send-checked-values", {name: this.formatedLabelName, filters: this.sCheckbox})
        },
        formatId(str, i) {
            var { ceil } = Math
            return "id-" + str.split(" ").join("-").toLowerCase() + "-" + ceil((i + 10))
        },
        showFilters() {
            this.filtersActive = !this.filtersActive
        },
        clearFilters() {
            this.$el.children[1].children.forEach((c) => { 
                var input = c.children[0]

                if (input.checked) input.checked = false
            })

            this.sCheckbox = []

            this.$emit("send-checked-values", {name: this.formatedLabelName, filters: this.sCheckbox})
        }
      },
    }
</script>
<style scoped>
    @import "../scss/components/component-filter.scss";
</style>