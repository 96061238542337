import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
// import VueCarousel from 'vue-carousel'
import VueSocialSharing from 'vue-social-sharing'
import { library } from '@fortawesome/fontawesome-svg-core'
import { BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faFacebookF, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueMeta)
// Vue.use(VueCarousel)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSocialSharing)

Vue.config.productionTip = false
Vue.component('font-awesome-icon', FontAwesomeIcon)

library.add(fas)
library.add(faFacebook, faFacebookF, faTwitter, faLinkedin, faInstagram)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
