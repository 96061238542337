<template>
    <div class="data-card__c">
        <span class="data-num__c">{{ num }}</span>
        <h3 class="data-metric__c">{{ name }}</h3>
        <div v-if="subMetrics.length > 0">
            <div class="sub-metrics-container__c">
                <div v-for="(sub, index) of subMetrics" :key="index">
                    <span class="sub-num__c">{{ sub.num }}</span>
                    <h4 class="sub-text__c">{{ sub.name }}</h4>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            name: String,
            num: String,
            subMetrics: {
                type: Array,
                default: function() {
                    return []
                }
            },
        },
        computed: {
            hasSubmetrics() {
                return (this.subMetrics.length) ? "data-card__c" : "data-card__c no-sub-metrics__c"
            }
        }
    }
</script>
<style scoped>
    @import "../scss/components/component-data-card.scss";
</style>