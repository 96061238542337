<template>
    <div class="domain">
        <HeaderImg 
            :mainText="domain.title.toUpperCase()" 
            :headerImg="require('../assets/domains/' + this.$route.params.team + '.png')"
        />
        <div class="domain-content__c">
            <div v-if="domain.data.length">
                <div class="data-container__c">
                    <DataCard 
                        v-for="(d, index) of domain.data"
                        :key="index"
                        :name="d.name"
                        :num="d.num"
                        :subMetrics="d.subMetrics"
                    />
                </div>
            </div>
        </div>
        <div class="domain-content__c domain-short-text__c" v-html="domain.shortText"></div>
        <hr style="background-color: #ffcb04; height: 3px; margin: auto; width: 50%;" />
        <div class="domain-content__c" v-html="domain.fullDesc"></div>
        <div v-if="domain.highlights.length">
            <HighLight :highlight="domain.highlights[0].highlight" :name="domain.highlights[0].name" />
        </div>
        <div class="domain-content__c">
            <div v-if="domain.relatedReports.length">
                <h3>Related Posts</h3>
                <div class="report-cards">
                    <ReportCard
                        v-for="(report, index) of domain.relatedReports"
                        :key="index"
                        :title="report.post_title"
                        :description="report.post_excerpt"
                        :img="report.img_thumb"
                        :categories="report.category"
                        :id="report.ID"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    // import HeaderSection from '@/components/HeaderSection.vue'
    import HeaderImg from '@/components/HeaderImg.vue'
    import ReportCard from '@/components/ReportCard.vue'
    import HighLight from '@/components/HighLight.vue'
    import DataCard from '@/components/DataCard.vue'

    export default {
        name: "Team",
        components: {
            ReportCard,
            HeaderImg,
            DataCard,
            HighLight
        },
        props: {
            name: String,
        },
        computed: {
            ...mapGetters(["routedDomain"]),
            domain() {
                return this.routedDomain(this.$route.params.team)
            },
            domainImg() {
                return "https://ai.umich.edu/wp-content/uploads/2021/08/" + this.$route.params.team + ".png"
            }
        },
    }
</script>
<style scoped>
    @import "../scss/views/view-team.scss";
    @import "../scss/layouts/layout-grid-report-cards.scss";
</style>