<template>
    <!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="personalized_learning" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" viewBox="0 0 537.5 870.6" style="enable-background:new 0 0 537.5 870.6;" xml:space="preserve">
        <path d="M179.5,648.3c1.9-35.7-2.2-72.5-18.4-104.2C135.4,493.8,83.8,464.9,52,419C20.8,374,4.4,320.3,5,265.5
        C6.7,121.4,124.9,4.8,269,5c145.5,0.2,263.5,118.2,263.5,263.8c0,50.7-14.7,100.4-42.1,143c-13.5,20.8-28.9,37.5-47.5,53.5
        c-19.6,17-38.3,35.7-50.1,59.1c-19.2,38.1-17.7,82.9-15.7,125.5"/>
        <path d="M205.7,626.6H352c37.5,0,67.9,30.4,67.9,67.9v57.4c0,37.5-30.4,67.9-67.9,67.9H205.7
        c-37.5,0-67.9-30.4-67.9-67.9v-57.4C137.8,657,168.2,626.6,205.7,626.6z"/>
        <line x1="278.9" y1="637.2" x2="278.9" y2="484.9"/>
        <path d="M326.5,817.9c0.4,26.3-20.6,47.9-46.9,48.3s-47.9-20.6-48.3-46.9c0-0.5,0-0.9,0-1.4"/>
        <path d="M146.6,250.7l130,54.5l134.5-54.5l-0.1,94.2l-27.2,16.7c-68.5,25.8-144.3,25-212.2-2.2L146.6,347L146.6,250.7z"
        />
        <polygon points="495.1,221 278.3,305.9 62.6,218.2 278.3,136.1 "/>
        <line x1="100.8" y1="227.9" x2="100.8" y2="301.2"/>
    </svg>
</template>
<script>
    export default {
        props: {
            fill: {
                type: String,
                default: "#2c3e50"
            },
            stroke: {
                type: String,
                default: "transparent"
            }
        }
    }
</script>