<template>
    <div class="report-container__c">
        <HeaderImg :headerImg="require('../assets/domains/' + teamImgHeader + '.png')" :mainText="report.post_title" />
        <div class="report-content__c">
            <!-- <h1 class="report-title__c">{{ report.post_title }}</h1> -->
            <BaseIcon name="ToolsForLearning" />
            <div class="report-header__c">
                <div class="report-category-header__c">
                    <h4>At a Glance</h4>
                    <div class="aag-txt__c">
                        {{ report.acf.summary }}
                    </div>
                    <div class="social-sharing__c">
                        <span class="social-sharing-heading__c">Share our story:</span>
                        <ul>
                            <li>
                                <ShareNetwork
                                    network="facebook"
                                    :url="socialUrl"
                                    :title="report.post_title"
                                ><font-awesome-icon :icon="['fab', 'facebook']" size="2x"></font-awesome-icon></ShareNetwork>
                            </li>
                            <li>
                                <ShareNetwork
                                    network="twitter"
                                    :url="socialUrl"
                                    :title="report.post_title"
                                ><font-awesome-icon :icon="['fab', 'twitter']" size="2x"></font-awesome-icon></ShareNetwork>
                            </li>
                            <li>
                                <ShareNetwork
                                    network="linkedin"
                                    :url="socialUrl"
                                ><font-awesome-icon :icon="['fab', 'linkedin']" size="2x"></font-awesome-icon></ShareNetwork>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="report-category-header__c">
                    <h4>Areas of Impact</h4>
                    <div class="aoi-icons__c">
                        <div class="aoi-icon__c"
                            v-for="(area, index) of report.acf.areas_of_impact"
                            :key="index"
                        >
                            <BaseIcon :name="formatIconClass(area)" fill="#2c3e50" />
                            <h3 class="aoi-label__c">{{ area.split("/").join(" ") }}</h3>
                        </div>
                    </div>
                    <div class="inner-report-meta__c">
                        <div class="geography__c">
                            <h4>Geography</h4>
                            <ul>
                                <li>{{ report.acf.geograph_audience }}</li>
                            </ul>
                        </div>
                        <!-- <div class="categories__c">
                            <h4>Categories</h4>
                            <ul>
                                <li v-for="(category, index) of report.category" :key="index">{{ category.name }}</li>
                            </ul>
                        </div> -->
                        <div v-if="report.tags.length">
                            <div class="tags__c">
                                <h4>Tags</h4>
                                <ul>
                                    <li v-for="(tag, index) of report.tags" :key="index">{{ tag.name }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="priorities__c">
                            <h4>Priorities</h4>
                            <ul>
                                <li>{{ report.acf.domains }}</li>
                            </ul>
                        </div>
                        <!-- <div class="key-individuals__c">
                            <h4>Key Individuals</h4>
                            <ul>
                                <li
                                    v-for="(individual, index) of report.acf.key_individuals.split(',')"
                                    :key="index"
                                >{{ individual }}</li>
                            </ul>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="ribbon__c"></div>
        <div class="report-content__c" v-html="report.acf.full_description"></div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'

    import BaseIcon from '@/components/BaseIcon.vue'
    import HighLight from '@/components/HighLight.vue'
    import HeaderImg from '@/components/HeaderImg.vue'

    export default {
        data() {
            return {
                id: this.$route.params.reportID,
            }
        },
        components: {
            BaseIcon,
            HighLight,
            HeaderImg,
        },
        computed: {
            ...mapGetters(["reports", "routedReport"]),
            report() { return this.routedReport(this.$route.params.reportID) },
            socialUrl() {
                return location.href
            },
            teamImgHeader() {
                return this.report.acf.domains.replaceAll(" ", "-").toLowerCase()
            }
        },
        methods: {
            formatIconClass(icon) {
                return icon.replaceAll(" ", "-").replaceAll("/", "-").toLowerCase()
            }
        }
    }
</script>
<style scoped>
    @import "../scss/views/view-report.scss";

    .base {
        fill: none;
        stroke: #2c3e50;
        stroke-width: 16px;
        height: 65px;
        transition: all 0.4s ease-in-out;
        width: 65px;
    }

    .base.white {
        stroke: white;
    }
</style>