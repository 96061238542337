<template>
  <div class="home">
    <div v-if="!reports">No data retrieved...</div>
    <div v-if="reports.length">
      <HeaderImg :mainText="'Designing the Future of Learning'" />
      <div class="page-content__c">
        <div class="header-content__c">
          <div class="header-img__c">
            <img src="@/assets/AI_James-headshot-300px-v1.png" alt="James DeVaney" />
          </div>
          <div class="header-text__c">
            <h3>Header</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore 
magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo 
consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <a href="#" class="letter-btn__c">Learn More</a>
          </div>
        </div>
        <div class="filter-section" :class="{active: filtersActive}">
          <div class="filter-header__c">
            <div class="filter-heading__c">
              <h3>Filters</h3>
            </div>
            <div class="filter-btn-container__c">
              <button class="filter-btn__c" @click="clearFilters">Clear All</button>
            </div>
          </div>
          <div class="filter-container">
            <FilterComponent title="Areas of Impact" :uid="2" :filters="areasOfImpact" @send-checked-values="ingestValues" ref="fc2" />
            <!-- <FilterComponent title="Initiatives" :uid="3" :filters="signatureInitiatives" @send-checked-values="ingestValues" ref="fc3" /> -->
            <FilterComponent title="Geography" :uid="4" :filters="geographAudience" @send-checked-values="ingestValues" ref="fc4" />
            <FilterComponent title="Priorities" :uid="1" :filters="domain" @send-checked-values="ingestValues" ref="fc1" />
          </div>
        </div>
        <div class="report-cards">
          <ReportCard
            v-for="(report, index) of getFilteredReports"
            :key="index"
            :title="report.post_title"
            :description="report.post_excerpt"
            :img="report.img_thumb"
            :categories="report.category"
            :areas="report.acf.areas_of_impact"
            :id="report.ID"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ReportCard from '@/components/ReportCard.vue'
import FilterComponent from '@/components/FilterComponent.vue'
import HeaderImg from '@/components/HeaderImg.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
      areas_of_impact: [],
      priorities: [],
      initiatives: [],
      geography: [],
      title: "Annual Report",
      intro: "Here is some intro text.",
      heroImg: "",
      filtersActive: false,
    }
  },
  components: {
    ReportCard,
    FilterComponent,
    HeaderImg,
  },
  computed: {
    ...mapGetters(["reports", "categories", "filteredReports", "areasOfImpact", "signatureInitiatives", "geographAudience", "domain", "numberOfDomain"]),
    getFilteredReports() {
      if (!this.areas_of_impact.length && !this.initiatives.length && !this.priorities.length && !this.geography.length) return this.reports  

      var fReports = []

      this.reports.forEach((r) => {
        var acf = r.acf

        acf.areas_of_impact.forEach((a) => {
          this.areas_of_impact.forEach((i) => {
            if (i === a && !fReports.includes(r)) fReports.push(r)
          })
        })

        this.geography.forEach((g) => { if (g === acf.geograph_audience && !fReports.includes(r)) fReports.push(r) })
        this.initiatives.forEach((i) => { if ( i === acf.signature_initiatives && !fReports.includes(r)) fReports.push(r) })
        this.priorities.forEach((d) => { if (d === acf.domains && !fReports.includes(r)) fReports.push(r) })
      })

      return fReports
    },
  },
  methods: {
    ingestValues(values) {
      this[values.name] = values.filters
    },
    clearFilters() {
      this.areas_of_impact = []
      this.priorities = []
      this.initiatives = []
      this.geography = []

      Object.keys(this.$refs).forEach((fc) => {
        this.$refs[fc].clearFilters()
      })

    }
  }
}
</script>
<style scoped>
  @import "../scss/views/view-home.scss";
</style>
