<template>
    <div class="header__c" :style="{ 'background-image': 'url(' + headerImgEmpty + ')' }">
        <div class="mainText__c">
            {{ mainText }}
        </div>
        <div v-if="subText">
            <div class="subText__c">
                {{ subText }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HeaderImg",
        props: {
            mainText: String,
            subText: String,
            headerImg: String,
        },
        computed: {
            headerImgEmpty() {
                return (this.headerImg) ? this.headerImg : "https://ai.umich.edu/wp-content/uploads/2021/07/Header.png"
            }
        },
    }
</script>

<style scoped>
    @import "../scss/components/component-header.scss";
</style>