<template>
    <!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="cirricular_innovation_filled_in"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 825.6 892.2"
        style="enable-background:new 0 0 825.6 892.2;" xml:space="preserve">
    <path d="M663.8,650.1l3.4-86.1c0.5-11.9,4-23.4,10.3-33.5l17.6-28.3c5.8-9.2,13.2-17.3,21.8-23.8
        c65.4-49.9,103.8-127.4,103.7-209.6C820.6,123.2,702.7,5.2,557.1,5c-79.5-0.1-151.2,35.4-199.8,91.4C318,142,293.9,201,293.1,265.6
        c-1,78.8,33.3,153.9,93.5,204.6c8.6,7.3,16.5,15.3,23.7,23.9l39.1,47c6,7.2,10.2,15.7,12.3,24.8l0.2,0.9"/>
    <path d="M504.7,646.4H651c37.5,0,67.9,30.4,67.9,67.9v57.4c0,37.5-30.4,67.9-67.9,67.9H504.7
        c-37.5,0-67.9-30.4-67.9-67.9v-57.4C436.8,676.8,467.2,646.4,504.7,646.4z"/>
    <line x1="567.8" y1="652.9" x2="567.8" y2="500.6"/>
    <path d="M630.4,839.6c0.4,26.3-20.6,47.9-46.9,48.3s-47.9-20.6-48.3-46.9c0-0.5,0-0.9,0-1.4"/>
    <path d="M684.3,278.6l28.5-15.8l-6.9-25.9l-32.6,0.5l-12.1-20.9l16.8-28l-18.9-18.9l-28,16.8l-20.9-12.1l0.5-32.6
        l-25.9-6.9L569,163.3h-24l-15.9-28.5l-25.9,6.9l0.5,32.6l-20.9,12.1l-28-16.8l-18.9,18.9l16.8,28l-12.1,20.9L408,237
        c-3.7,13.9-3.2,12-6.9,25.9l28.5,15.8v24.1l-28.5,15.8c3.7,13.9,3.2,12,6.9,25.9l32.6-0.5l12.1,20.9l-16.8,28l18.9,18.9l28-16.8
        l20.9,12.1l-0.5,32.6l25.9,6.9L545,418h24.1l15.8,28.5l25.9-6.9l-0.5-32.6l20.8-12.1l28,16.8l18.9-18.9l-16.8-28l12.1-20.9l32.6,0.5
        l6.9-25.9l-28.5-15.8V278.6z M557,368.6c-43,0-77.9-34.9-77.9-77.9s34.9-77.9,77.9-77.9s77.9,34.9,77.9,77.9l0,0
        C634.9,333.7,600,368.6,557,368.6z"/>
    <path d="M100.5,247.4v320.5h-0.2c-2.3,0-4.5,0.1-6.8,0.2C58,570.5,27.6,591.5,12,621.5V219.4
        c0-67.3,53-121.9,118.3-121.9h233.3c-38.7,45.3-62.3,103.8-63.1,167.7c-1,78.8,33.3,153.9,93.5,204.6c8.6,7.3,16.5,15.3,23.7,23.9
        l39.1,47c6,7.2,10.2,15.7,12.3,24.8l0.5,2.3H114.1V247.4H100.5z"/>
    <path d="M193.1,659.3h278.8c-12.7,9.2-22,22.6-26,38.2H241.3v13.6h202.6c-0.1,1.1-0.1,2.1-0.1,3.2v57.4
        c0,1.1,0,2.2,0.1,3.3H136.3c-13.6-1-30.8-4.2-48.9-12.5c-6.7-3.1-18.8-8.7-31.7-20.1c-5.1-4.4-14.9-13.2-23.1-27.7
        c-9-15.9-11.8-30.9-12.8-40c-0.4-5.2-3.2-41.2,23.6-68.8c1.5-1.7,23.4-23.7,56.1-26.2c2.2-0.2,4.5-0.3,6.8-0.3h0.2l1.6,0
        c0.5,0,1.1,0,1.6,0h3.4v-0.1h360.4l15.2,66.3H193.1V659.3z"/>
    <path d="M100.5,579.4c1.1,0,2.1,0,3.2,0.1L100.5,579.4L100.5,579.4z"/>
    <rectx="107.1" y="579.4"  width="7" height="0.1"/>
</svg>

</template>
<script>
    export default {
        props: {
            fill: {
                type: String
            }
        }
    }
</script>