<template>
    <div>
        <b-navbar class="ai-nav__c" toggleable="lg" :fixed="isFixed">
            <b-navbar-brand>
                <router-link to="/">
                    <img id="logo" src="@/assets/impact-logo.png" alt="CAI Impact">
                </router-link>
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ms-auto">
                    <b-nav-item><router-link class="router-link__c" to="/"><span class="router-link-icon__c"><font-awesome-icon icon="home" size="lg" /></span><span class="home-text__c">Home</span></router-link></b-nav-item>
                    <!-- <b-nav-item><router-link class="router-link__c" to="/">Home</router-link></b-nav-item> -->
                    <!-- <b-nav-item-dropdown text="Teams" no-caret right>
                        <b-dropdown-item
                            v-for="(domain, index) of domains"
                            :key="index"
                        >
                            <router-link class="router-link__c" :to="'/teams/' + domain.nav">{{ domain.title }}</router-link>
                        </b-dropdown-item>
                    </b-nav-item-dropdown> -->
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        name: "Nav",
        data() {
            return {
                isFixed: null
            }
        },
        computed: {
            ...mapGetters(["domains"]),
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener("resize", this.onResize)
            })
        },
        methods: {
            onResize() {
                this.isFixed = (window.innerWidth < 991) ? "top" : "null"
            }
        }
    }
</script>

<style>
    @import "../scss/components/component-navbar.scss";
</style>