<template>
    <div class="report-card">
        <!-- <img class="report-card-image" :src="img" /> -->
        <router-link class="report-card-link__c" :to="{ name: 'report', params: { reportID: id } }">
            <div class="report-card-image" :style="{'background-image': 'url(' + imgEmpty + ')', 'width': '100%'}"></div>
        <div class="report-card-content">
            <h3>{{ title }}</h3>
            <p>{{ description }}</p>
            <ul class="aoi__c">
                <li
                    v-for="(area, index) of areas"
                    :key="index"
                ><BaseIcon :name="formatIconClass(area)" /></li>
            </ul>
        </div>
        </router-link>
    </div>
</template>
<script>
    import BaseIcon from "@/components/BaseIcon.vue"

    export default {
        props: {
            title: String,
            description: String,
            img: [String, Boolean],
            areas: Array,
            id: Number,
        },
        components: {
            BaseIcon
        },
        data() {
            return {
                icons: {
                    "Gameful Pedagogy": "gamepad",
                    "Software Tools": "desktop",
                    "Faculty Partners": "handshake"
                }
            }
        },
        computed: {
            imgEmpty() {
                return (this.img) ? this.img : "https://ai.umich.edu/wp-content/uploads/2021/03/giving-blueday-2021-feature.jpg"
            }
        },
        methods: {
            formatIconClass(icon) {
                return icon.replaceAll(" ", "-").replaceAll("/", "-").toLowerCase()
            }
        }
    }
</script>
<style scoped>
    @import "../scss/components/component-report-card.scss";

    .base {
        fill: transparent;
        stroke: white;
        stroke-width: 25px;
        height: 50px;
        transition: all 0.4s ease-in-out;
        width: 50px;
    }

    .report-card:hover .base {
        stroke: #00274c;
    }

    .aoi__c li {
        margin-right: 20px;
    }
</style>