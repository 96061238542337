<template>
    <!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="tools_for_learning" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 741.5 896" style="enable-background:new 0 0 741.5 896;" xml:space="preserve">
        <path d="M344.3,895c0,0-7.9-38.3-12.2-50.6c-9.9-28-40.4-84.9-70-96c-34.3-12.9-130.7,18.5-156.3-9.2
        c-14-15.2-4.2-73.3-18.9-87.8c-4.8-4.7-17.1-7.1-17.9-13.7c-0.9-7.8,3.3-8.4,14.8-20c6.8-6.8-27-14.1-30.6-23
        c-4.7-11.2,6.9-48.2,6.9-48.2s-62.3-19.6-54.4-38.1C20,474.7,76.1,392.3,83.3,353.2c3.2-17.3-9.8-54.6-9.8-71.9
        C73.5,99.8,216.5,4.9,398,4.9s338.5,100.4,338.5,281.9c0,81.8-91.6,236.6-118,315.6c-14.3,42.9,63.3,177.7,63.3,177.7"/>
        <path :fill="fill" d="M559.1,294.5l35.7-19.8c-4.7-17.4-4-15-8.7-32.4l-40.9,0.7l-15.1-26.2l21-35L527.4,158l-35,21l-26.2-15.1
        l0.7-40.9l-32.4-8.7l-19.8,35.7h-30.2l-19.8-35.7l-32.4,8.7l0.7,40.9L306.6,179l-35-21l-23.7,23.7l21,35L253.8,243l-40.9-0.7
        c-4.7,17.4-4,15-8.7,32.4l35.7,19.8v30.2l-35.7,19.9c4.7,17.4,4,15,8.7,32.4l40.9-0.7c6.8,11.8,8.3,14.4,15.1,26.2l-21,35l23.7,23.7
        l35-21l26.2,15.1l-0.7,40.9l32.4,8.7l19.8-35.7h30.2l19.8,35.7l32.4-8.7l-0.7-40.9l26.2-15.1l35,21l23.7-23.7l-21-35
        c6.8-11.8,8.3-14.4,15.1-26.2l40.9,0.7c4.7-17.4,4-15,8.7-32.4l-35.7-19.8V294.5z M399.5,407.3c-53.9,0-97.6-43.7-97.6-97.6
        c0-53.9,43.7-97.6,97.6-97.6s97.6,43.7,97.6,97.6c0,0,0,0,0,0C497.1,363.6,453.4,407.3,399.5,407.3L399.5,407.3z"/>
    </svg>
</template>
<script>
    export default {
        props: {
            fill: {
                type: String,
            }
        }
    }
</script>