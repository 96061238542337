<template>
    <div class="loading__c">
        <div class="bar__c"></div>
    </div>
</template>

<script>
    export default {
        name: "LoadingBar",
    }
</script>

<style scoped>
    @import "../scss/components/component-loading-bar.scss";
</style>