<template>
  <div id="app">
    <Nav />
    <div v-if="loading">
      <LoadingBar />
    </div>
    <div v-else>
      <router-view/>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Nav from "@/components/Nav.vue"
  import Footer from "@/components/Footer.vue"
  import LoadingBar from "@/components/LoadingBar.vue"
  import { mapState } from 'vuex'

  export default {
    metaInfo: {
      title: "Center for Academic Innovations Impact Report",
    },
    components: {
      Nav,
      Footer,
      LoadingBar,
    },
    computed: {
      ...mapState(["loading"])
    },
    mounted() {
      window.onbeforeunload = function() {
        window.localStorage.clear()
      }
    }
  }
</script>

<style>
@import "./scss/main.scss";
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin: auto;
  width: 100%;
}

.links {
  text-align: right;
}

.view {
  margin: 90%;
}

</style>
