import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    reports: [],
    fReports: [],
    categories: [],
    areasOfImpact: [],
    initiatives: [],
    geography: [],
    domains: {
      "online-learning-initiative": {
        title: "Online Learning Initiative",
        shortText: `
          <p>At the University of Michigan we believe a peaceful, equitable and empowered society is dependent on learners everywhere adopting a learning lifestyle. To that end, a significant part of our institution’s mission is to develop leaders and citizens who challenge the present and enrich the future.</p>
        `,
        fullDesc: `
          <p>Our global network of learners can explore new passions, expand their professional skills to ensure they are ready for the jobs of the next decade or learn new skills in order to take an entirely new career path.</p>
          <p>This work isn’t possible without a robust, dedicated staff with the ability to work with innovative faculty partners to bring education into the online space including our team of educational designers, behavioral scientists, researchers and our production team to make it all a reality.</p>
          <p>Guided by our commitments to access, affordability, excellence, and inclusion, we’ve developed learning experiences that act like the onramps, bridges, and intersections of a learner’s educational journey. Onramp learning experiences meet learners where they are as they begin exploring new skills and concepts. We’ve created intersections for learners who are hungry to solve problems that require interdisciplinary thinking. Other learning experiences provide bridges for those learners who are looking for opportunities to connect with people in authentic ways across space, time, discipline, culture, language and generation.</p>
        `,
        highlights: [
          { highlight: "Teach-Outs are a powerful way to enhance understanding of some important topics.", name: "Kate Kifa, Ukraine" },
          { highlight: "The MADS program provided me the professional growth and community I was really looking for.", name: "Ani Madurkar, Michigan" }
        ],
        nav: "online-learning-initiative",
        filter: "Online Learning",
        data: [
          { name: "Learning Experiences Available", num: "250+" },
          { name: "Open Learning Enrollments", num: "15 Million" },
          { name: "Never Attended U-M", num: "96%" },
        ]
      },
      "software-tools": {
        title: "Software Tools",
        shortText: "Here is the Software Tools short text",
        fullDesc: "",
        highlights: [
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." },
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." },
        ],
        nav: "software-tools",
        filter: "Software Tools",
        data: [
          { 
            name: "Software Applications", 
            num: "7",
            subMetrics: [
              { name: "Libraries Used", num: "12" },
              { name: "Compiled", num: "100" }
            ] 
          },
          { name: "Developers", num: "39" },
          { name: "Resources", num: "23" },
        ]
      },
      "research": {
        title: "Research",
        shortText: "",
        fullDesc: "",
        highlights: [
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." },
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." }
        ],
        nav: "research",
        filter: "",
        data: []
      },
      "public-engagement": {
        title: "Public Engagement",
        shortText: "",
        fullDesc: "",
        highlights: [
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." },
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." }
        ],
        nav: "public-engagement",
        filter: "Public Engagement",
        data: []
      },
      "xr": {
        title: "XR",
        shortText: "",
        fullDesc: "",
        highlights: [
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." },
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." }
        ],
        nav: "xr",
        filter: "XR",
        data: []
      },
      "media-design": {
        title: "Media Design",
        shortText: "Here is Media Design's short text.",
        fullDesc: "Media Design's full description",
        highlights: [
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." },
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." },
        ],
        nav: "media-design",
        filter: "Media Design",
        data: [
          { 
            name: "Films", 
            num: "30",
            subMetrics: [
              { name: "Cameras", num: "4" },
              { name: "Directors", num: "2" }
            ]
          }
        ]
      },
      "online-hybrid-programs": {
        title: "Online Hybrid Programs",
        shortText: "",
        fullDesc: "",
        highlights: [
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." },
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." },
        ],
        nav: "online-hybrid-programs",
        filter: "Online & Hybrid Programs",
        data: []
      },
      "behavioral-science": {
        title: "Behavioral Science",
        shortText: "",
        fullDesc: "",
        highlights: [
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." },
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." }
        ],
        nav: "behavioral-science",
        filter: "Behavioral Science",
        data: []
      },
      "operations": {
        title: "Operations",
        shortText: "",
        fullDesc: "",
        highlights: [
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." },
          { highlight: "The Impact Site is the BEST thing ever", name: "Edward L. Cochran Jr." }
        ],
        nav: "operations",
        filter: "",
        data: []
      },
    },
    loading: false,
  },
  mutations: {
    initStore(state) {
      if (localStorage.getItem("reports")) {
        state.reports = JSON.parse(localStorage.getItem("reports"))
      }
    },
    updateReports(state, reports) {
      state.reports = reports
    },
    updateCategories(state, categories) {
      state.categories = categories
    },
    loadingStatus(state, currentStatus) {
      state.loading = currentStatus
    },
  },
  actions: {
    async getReports({commit}) {
      if (localStorage.getItem("reports")) {
        commit("updateReports", JSON.parse(localStorage.getItem("reports")))
        // this.state.reports = JSON.parse(localStorage.getItem("reports"))
        return
      }

      commit('loadingStatus', true)

      var response = await axios.get("https://ai.umich.edu/wp-json/ai/v1/all/impact_report").catch((res) => { res })

      response = (response) ? response.data : false

      localStorage.setItem("reports", JSON.stringify(response))

      commit('updateReports', response)
      commit('loadingStatus', false)
    }
  },
  getters: {
    reports(state) {
      return state.reports
    },
    domains(state) {
      return state.domains
    },
    routedDomain(state) {
      return function(domain) {
        var rDomain = state.domains[domain]

        if (!rDomain) {
          return {title: "No Domain Route"}
        }

        var relatedReports = []

        state.reports.forEach(function(r) {
          var isReleated = false

          r.category.forEach(function(c) {
            if (c.name.toLowerCase() === rDomain.filter.toLowerCase()) {
              isReleated = true
            }
          })

          if (isReleated) {
            relatedReports.push(r)
          }
        })

        relatedReports = relatedReports.slice(0,3);

        rDomain.relatedReports = relatedReports

        return rDomain
      }
    },
    routedCategory(state) {
      return function(category) {
        var relatedCategory = []

        state.reports.forEach(function(r) {
          var isRelated = false

          r.category.forEach(function(c) {
            if (c.name.toLowerCase() === category.toLowerCase()) {
              isRelated = true
            }
          })

          if (isRelated) {
            relatedCategory.push(r)
          }
        })

        return relatedCategory
      }
    },
    categories(state) {
      var categories = []

      state.reports.forEach(function(report) {
        report.category.forEach(function(category) {
          if (!categories.includes(category.name)) {
            categories.push(category.name)
          }
        })
      })

      return categories
    },
    domain(state) {
      var domains = []

      state.reports.forEach(function(report) {
        if (!domains.includes(report.acf.domains)) {
          domains.push(report.acf.domains)
        }
      })

      domains.sort(function(a,b) {
        if (a < b) { return -1 }
        if (a < b) { return 1 }
        return 0
      })

      return domains
    },
    areasOfImpact(state) {
      var areasOfImpact = []

      state.reports.forEach(function(report) {
        report.acf.areas_of_impact.forEach(function(i) {
          if (!areasOfImpact.includes(i)) {
            areasOfImpact.push(i)
          }
        })
      })

      areasOfImpact.sort(function(a,b) {
        if (a < b) { return -1 }
        if (a < b) { return 1 }
        return 0
      })

      return areasOfImpact
    },
    signatureInitiatives(state) {
      var signatureInitiatives = []

      state.reports.forEach(function(report) {
        if (!signatureInitiatives.includes(report.acf.signature_initiatives) && !report.acf.signature_initiatives == "") {
          signatureInitiatives.push(report.acf.signature_initiatives)
        }
      })

      return signatureInitiatives
    },
    geographAudience(state) {
      var geographAudience = []

      state.reports.forEach(function(report) {
        if (!geographAudience.includes(report.acf.geograph_audience)) {
          geographAudience.push(report.acf.geograph_audience)
        }
      })

      geographAudience.sort(function(a,b) {
        if (a < b) { return -1 }
        if (a < b) { return 1 }
        return 0
      })

      return geographAudience
    },
    loadingStatus(state) {
      return state.loading
    },
    routedReport(state) {
      return function(id) {
        return state.reports.find(function(r) {
          return r.ID === Number(id)
        })
      }
    },
    numberOfDomain: (state) => (metric) => {
      var l = 0

      state.reports.forEach(function(r) {
        if (r.acf.domains == metric) {
          l += 1
        }
      })

      return l
    }
  },
  modules: {}
})
