<template>
    <div class="highlight__c">
        <span class="highlight-text__c">{{ highlight }}</span>
        <br />
        <span class="highlight-author__c">- {{ name }}</span>
    </div>
</template>

<script>
    export default {
        name: "HighLight",
        props: {
            highlight: String,
            name: String
        }
    }
</script>

<style scoped>
    .highlight__c {
        background-color: #f3f3f3;
        margin-bottom: 30px;
        padding: 40px 0;
        text-align: center;
    }

    .highlight-text__c::before {
        content: "\"";
    }

    .highlight-text__c {
        display: block;
        font-weight: 600;
        font-size: 2em;
        font-style: italic;
        margin: auto;
        width: 70%;
    }

    .highlight-text__c::after {
        content: "\"";
    }
</style>