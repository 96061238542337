<template>
    <div class="category">
        <HeaderSection :mainText="this.$route.params.category" />
        <div class="page-section__c">
            <div class="report-cards">
                <ReportCard 
                    v-for="(report, index) of category"
                    :key="index"
                    :title="report.post_title"
                    :description="report.post_excerpt"
                    :img="report.img_thumb"
                    :categories="report.category"
                    :id="report.ID"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ReportCard from '@/components/ReportCard.vue'
import HeaderSection from '@/components/HeaderSection.vue'

    export default {
        components: {
            ReportCard,
            HeaderSection
        },
        computed: {
            ...mapGetters(["routedCategory"]),
            category() {
                return this.routedCategory(this.$route.params.category)
            }
        }
    }
</script>
<style scoped>
    @import "../scss/layouts/layout-grid-report-cards.scss";

    .page-section__c {
        margin: auto;
        width: 90%;
    }

    @media screen and (max-width: 600px) {
        .page-section__c {
            width: 98%;
        }
    }
</style>