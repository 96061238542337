import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/Home.vue'
import Report from '../views/Report.vue'
import Team from '../views/Team.vue'
import Category from '../views/Category.vue'
import store from '@/store/index.js'

Vue.use(VueMeta)
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route // which is lazy-loaded when the route is visited.  component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/report/:reportID',
    name: 'report',
    component: Report
  },
  {
    path: '/category/:category',
    name: 'category',
    component: Category
  },
  // {
  //   path: '/teams/:team',
  //   name: 'team',
  //   component: Team
  // }
]

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  }
})

router.beforeEach((to, from, next) => {
  if (!store.state.reports.length) {
    store.dispatch('getReports')
  }
  next()
})

export default router
