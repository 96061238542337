<template>
    <footer>
        <div class="subcribe__c">
            <h3>Subscribe for Updates</h3>
            <p>Recieve notificiations for new opportunities and resources as they become available from CAI</p>
            <a href="https://ai.umich.edu/subscribe/" class="subscribe-btn__c">Subscribe</a>
        </div>
        <hr>
        <div class="links__c">
            <div class="link-section__c">
                <h3>Resources</h3>
                <ul class="resources__c">
                    <li class="resource__c"><font-awesome-icon icon="arrow-right" size="xs" /> <a href="https://ai.umich.edu/academic-innovation-website-privacy-policy/">Privacy Policy</a></li>
                    <li class="resource__c"><font-awesome-icon icon="arrow-right" size="xs" /> <a href="https://ai.umich.edu/distance-education-disclosures/">Distance Education Disclosures</a></li>
                    <li class="resource__c"><font-awesome-icon icon="arrow-right" size="xs" /> <a href="https://ai.umich.edu/policies/">Policies &amp; Resources</a></li>
                    <li class="resource__c"><font-awesome-icon icon="arrow-right" size="xs" /> <a href="https://ai.umich.edu/learning-analytics-guiding-principles/">Learning Analytics Guiding Principles</a></li>
                    <li class="resource__c"><font-awesome-icon icon="arrow-right" size="xs" /> <a href="https://ai.umich.edu/accessibility-guiding-principles/">Accessibility Guiding Principles</a></li>
                    <li class="resource__c"><font-awesome-icon icon="arrow-right" size="xs" /> <a href="https://ai.umich.edu/press-kits">Press Kits</a></li>
                </ul>
            </div>
            <div class="link-section__c">
                <h3>Meet</h3>
                <p>
                    <strong>Washington Lab</strong>
                    <br>
                    500 E. Washington Street, Suite B
                    <br>
                    Ann Arbor, MI 48104
                </p>
                <p>
                    <strong>Hatcher Lab</strong>
                    <br>
                    500 E. Washington Street, Suite B
                    <br>
                    Ann Arbor, MI 48104
                </p>
                <p>
                    <strong>5th Ave</strong>
                    <br>
                    210 S. 5th Ave
                    <br>
                    Ann Arbor, MI 48104
                </p>
            </div>
            <div class="link-section__c">
                <h3>Contact</h3>
                <p>
                    <a href="mailto: academicinnovation@umich.edu">academicinnovation@umich.edu</a>
                    <br>
                    <a href="tel: +734-764-7351">(734)-764-7351</a>
                </p>
            </div>
        </div>
        <div class="socials__c">
            <ul class="social-links__c">
                <li class="social-link__c"><a href="https://www.facebook.com/MichiganOnline/"><font-awesome-icon :icon="['fab', 'facebook-f']" size="lg" /></a></li>                    
                <li class="social-link__c"><a href="https://twitter.com/UMichiganAI"><font-awesome-icon :icon="['fab', 'twitter']" size="lg" /></a></li>                    
                <li class="social-link__c"><a href="https://www.instagram.com/michigan.online/"><font-awesome-icon :icon="['fab', 'instagram']" size="lg" /></a></li>                    
                <li class="social-link__c"><a href="https://www.linkedin.com/company/umichiganai/"><font-awesome-icon :icon="['fab', 'linkedin']" size="lg" /></a></li>                    
            </ul>
            <p>© {{ getYear }} The Regents of the University of Michigan</p>
        </div>
    </footer>
</template>
<script>
    export default {
        name: "Footer",
        computed: {
            getYear() {
                return new Date().getFullYear();
            }
        }
    }
</script>
<style scoped>
    @import "../scss/components/component-footer.scss";
</style>