<template>
    <!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="expand_public_purpose" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" viewBox="0 0 715.6 640.3" style="enable-background:new 0 0 715.6 640.3;" xml:space="preserve">
        <circle class="st0" cx="470" cy="211.8" r="198.2"/>
        <circle class="st0" cx="470" cy="211.8" r="125.5"/>
        <path class="st0" d="M523.7,211.8c0,29.6-24,53.6-53.7,53.6s-53.6-24-53.6-53.7c0-29.6,24-53.6,53.7-53.6c9.7,0,19.2,2.6,27.5,7.6"
        />
        <line class="st0" x1="648" y1="64.8" x2="481.5" y2="210.1"/>
        <path class="st1" d="M587.1,118"/>
        <path class="st1" d="M577.4,69.2"/>
        <path class="st1" d="M152.2,0.9"/>
        <path class="st1" d="M159.4,25.8"/>
        <polygon class="st2" points="582.9,121.9 588.6,68.8 666.5,0 660.7,53.7 "/>
        <polygon class="st2" points="715.6,52.5 662.1,51.5 584,120 638,121 "/>
        <path class="st2" d="M54.3,640.3l123-80c77.6,42.4,161.2,81.8,249.6,80c98.5-2,190-56,258.8-126.5c4.5-4.6,9.2-10.4,7.9-16.7
        c-2.2-10-16.3-10.4-26.4-8.4c-32.9,6.6-58.6,16.1-89.3,29.6c-14.7,6.5-44.9,24.2-61.3,28.1c-34.7,8.3-100.3,8.7-139.9,1.5
        c-19.3-3.5-66.4-16.2-75.2-33.7c-2.4-4.9-0.4-19,4.5-16.5c52.3,27.2,80,29.1,122.7,32c32,2.2,65,3.3,95.2-7.5
        c7-2.5,14-5.8,18.5-11.8s5.3-15.1,0.2-20.4c-3.4-3.6-8.6-4.8-13.5-5.8c-107.5-21.9-216-45.1-314.8-92.8c-3.4-1.7-10.9-3.3-10.9-3.3
        c-3.4,0.8-6.8,1.9-10,3.3C132,422.2,67.1,445.2,0,459.9L54.3,640.3z"/>
    </svg>
</template>
<script>
    export default {
        props: {
            fill: {
                type: String
            }
        }
    }
</script>