<template>
    <component
        role="img"
        class="base"
        :class="name"
        :is="iconComponent"
    ></component>
</template>
<script>
    import CollaborationPartnership from "./icons/IconCollaborationPartnership.vue"
    import CurricularInnovation from "./icons/IconCurricularInnovation.vue"
    import ExpandPublicPurpose from "./icons/IconExpandPublicPurpose.vue"
    import PersonalizeLearning from "./icons/IconPersonalizedLearning.vue"
    import ToolsForLearning from "./icons/IconToolsForLearning.vue"
    import EducationalDataResearch from "./icons/IconEducationalDataResearch.vue"
    import ExtendAcademicExcellence from "./icons/IconExtendAcademicExecellence.vue"

    const icons = {
        "collaboration-partnership"     : CollaborationPartnership,
        "curricular-innovation"         : CurricularInnovation,
        "expand-public-purpose"         : ExpandPublicPurpose,
        "personalized-learning"         : PersonalizeLearning,
        "tools-for-learning"            : ToolsForLearning,
        "educational-data-&-research"   : EducationalDataResearch,
        "extend-academic-excellence"    : ExtendAcademicExcellence

    }

    export default {
        props: {
            name: {
                type: String,
                required: true,
            }
        },
        components: {
            CollaborationPartnership,
            CurricularInnovation,
            ExpandPublicPurpose,
            PersonalizeLearning,
            ToolsForLearning
        },
        computed: {
            iconComponent() {
                return icons[this.name]
            }
        }
    }
</script>